@import "../../../styles/color";

.BusinessEnglish {
  margin-bottom: 64px;
  padding: 57px 0 0 0;

  .header {
    padding: 64px 32px;
    width: 100%;
    background-image: url("/images/pricing-header-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #0069ff;
    align-items: center;
    color: #fff;
    margin-bottom: 64px;

    .texts {
      width: 100%;

      .catchphrase {
        font-size: 28px;
        line-height: 40px;
        font-weight: 600;
      }
      .subtext {
        margin: 40px 0;
        opacity: 0.7;
        font-size: 16px;
        line-height: 1.44;
        > div {
          margin-top: 1rem;
        }
      }
    }

    button {
      width: 326px;
      max-width: 100%;
      height: 54px;
      border-radius: 2px;
      box-shadow: 0 4px 4px 0 rgba(21, 77, 240, 0.6);
      border: solid 1px #ffffff;
      background-color: transparent;
      color: #ffffff;
      font-weight: 600;
    }
    button:hover {
      background-color: #ffffff;
      color: #0b79ff;
    }
  }

  .tab-content {
    padding: 0 32px;
  }

  .etc-item {
    border-bottom: 1px solid #dbe2eb;
    padding: 16px 0;

    &:last-child {
      border-bottom: none;
    }
    .title {
      color: #a4aebc;
      font-weight: 600;
    }
    .text {
      color: #5d6775;
      margin-top: 16px;
    }
  }
}

.yellow-button {
  margin: 40px 32px 100px 32px;
  height: 64px;
  line-height: 64px;
  color: #ffffff;
  background-color: $grey100;
  border: none;
  text-align: center;
}

@media (min-width: 768px) {
  .BusinessEnglish {
    .header {
      padding: 80px;
      margin-bottom: 80px;

      .texts {
        margin: 0 auto;
        width: 720px;
        .catchphrase {
        }
        .subtext {
          > div {
            margin-top: 0;
          }
        }
      }
    }

    .tab-content {
      padding: 0;
      margin: 0 auto;
      width: 720px;

      .etc-item {
        .text {
          margin-top: 0;
        }
      }
    }

  }
}
