.Footer {
  border-top: 1px solid #eeeeee;
  line-height: 1.6em;
  padding: 16px 32px 64px 32px;

  .company-info {
    span {
      line-height: 20px;
    }
  }
  .info {
    color: #b3b3b3;
    font-size: 12px;
    margin: 16px 0;
    clear: both;

    a {
      margin-right: 16px;
    }
  }

  .bi {
    img {
      height: 18px;
    }
  }

  @media (min-width: 992px) {
    .info {
      ul li {
        float: left;
        margin-right: 16px;
      }
    }
  }
}