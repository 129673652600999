
.Header {
  border-bottom: 1px solid #eeeeee;
  position: absolute;
  width: 100%;
  z-index: 10;
  background: #ffffff;
  font-size: 14px;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  .bi {
    font-size: 28px;
    line-height: 28px;
    color: #0069ff;
    font-weight: 600;
    position: relative;
    top: -3px;
  }

  a {
    color: #22292F;
  }

  .navbar-toggler {
    margin-right: -15px;
  }
}

.blinder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}