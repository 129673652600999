@import url(https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,600&display=swap&subset=korean);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap&subset=korean);
@import url(http://db.onlinewebfonts.com/c/67680f2eb947c5cbd58b40961b4a61e9?family=Apple+SD+Gothic+Neo);
.Home {
  color: #22292f;
  padding: 57px 16px 0 16px !important; }
  .Home section {
    padding: 64px 24px; }
  .Home section.services, .Home section.reviews {
    padding: 64px 32px; }
  .Home section.wable-news {
    padding: 32px 0 64px 0; }
  .Home section.wable-expertise {
    padding: 32px 0 64px 0; }
  .Home section.hero {
    background-color: #f1f6fd;
    padding: 80px 40px;
    margin-left: -16px;
    margin-right: -16px; }
    .Home section.hero img {
      position: absolute;
      z-index: 1; }
    .Home section.hero img.circle-small {
      height: 110px;
      top: -50px;
      left: -24px; }
    .Home section.hero img.circle-big {
      height: 183px;
      top: 75px;
      right: -120px; }
    .Home section.hero img.woman {
      display: none; }
    .Home section.hero img.womannotlogin {
      -webkit-transform: translateY(56px);
              transform: translateY(56px); }
    .Home section.hero .hero-inner {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      position: relative; }
      .Home section.hero .hero-inner .p-and-b {
        z-index: 2; }
      .Home section.hero .hero-inner .paragraph {
        z-index: 2; }
        .Home section.hero .hero-inner .paragraph h1 {
          font-size: 28px;
          line-height: 40px;
          letter-spacing: -1px;
          font-weight: 600; }
          .Home section.hero .hero-inner .paragraph h1 em {
            color: #0069ff; }
    .Home section.hero .button-wrap {
      margin-top: 60px;
      z-index: 2; }
  .Home section.hero-b {
    padding: 56px 12px;
    margin-left: -16px;
    margin-right: -16px;
    background-size: 276px 493px;
    background: #1f7ced url("/images/home-hero-b-bg.svg") no-repeat; }
    .Home section.hero-b h1 {
      font-size: 28px;
      font-weight: 600;
      line-height: 1.43;
      letter-spacing: -1px;
      color: #ffffff;
      margin-left: 12px; }
      .Home section.hero-b h1 em {
        color: #ffd703; }
    .Home section.hero-b .boxes {
      margin-top: 56px; }
      .Home section.hero-b .boxes .box {
        border-radius: 5px;
        margin-top: 16px;
        padding: 40px 30px;
        border: solid 1px #d7e3ee;
        background: #ffffff no-repeat right; }
        .Home section.hero-b .boxes .box .title {
          color: #414a53; }
          .Home section.hero-b .boxes .box .title h2 {
            display: inline;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -1px; }
          .Home section.hero-b .boxes .box .title span {
            margin-left: 8px;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: -1px;
            color: #414a53; }
        .Home section.hero-b .boxes .box p {
          margin-top: 16px;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -1px;
          color: #6d747e; }
          .Home section.hero-b .boxes .box p em {
            font-weight: 600; }
        .Home section.hero-b .boxes .box .buttons {
          margin-top: 48px;
          font-size: 14px; }
          .Home section.hero-b .boxes .box .buttons .primary-button {
            border-radius: 2px;
            background: #22292f;
            padding: 12px 32px;
            box-shadow: none; }
          .Home section.hero-b .boxes .box .buttons .secondary-line-button {
            padding: 12px 16px;
            color: #22292f;
            margin-left: 8px; }
  .Home section.services h2 {
    font-size: 20px;
    font-weight: 600; }
  .Home section.services h3 {
    font-size: 16px;
    font-weight: 600;
    color: #9b9b9b; }
  .Home section.services p {
    margin-top: 8px;
    color: #6d747e;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: -1px; }
  .Home section.services .service-type {
    margin-top: 64px; }
  .Home section.services .service-type:first-child {
    margin-top: 0; }
  .Home section.services .buttons {
    margin-top: 24px; }
    .Home section.services .buttons button {
      margin-right: 8px; }
  .Home section.partners {
    background-color: #ffffff;
    margin-left: -16px;
    margin-right: -16px; }
    .Home section.partners .section-title {
      font-size: 28px;
      letter-spacing: -1px;
      color: #9aa3ae;
      font-weight: 600;
      text-align: center; }
    .Home section.partners .section-title-black {
      font-size: 24px;
      letter-spacing: -1px;
      color: #615f5f;
      font-weight: 500;
      text-align: center; }
    .Home section.partners .cards {
      margin-top: 40px; }
      .Home section.partners .cards .box {
        width: 100%;
        padding: 36px 24px;
        border-radius: 5px;
        border: solid 1px #d7e3ee;
        background-color: #ffffff;
        margin-top: 12px;
        text-align: center; }
        .Home section.partners .cards .box .image-wrap {
          margin: 24px 0 16px 0;
          height: 152px;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          -webkit-align-items: center;
                  align-items: center; }
          .Home section.partners .cards .box .image-wrap > div {
            width: 230px;
            height: 152px; }
            .Home section.partners .cards .box .image-wrap > div img {
              max-width: 100%;
              max-height: 100%; }
        .Home section.partners .cards .box button {
          margin-top: 8px; }
        .Home section.partners .cards .box .card-title {
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          color: #414a53; }
        .Home section.partners .cards .box .card-desc {
          line-height: 1.75;
          letter-spacing: -1px;
          color: #6d747e; }
  .Home section.wable-system {
    background-color: #f9fcff;
    margin-left: -16px;
    margin-right: -16px; }
    .Home section.wable-system .section-title {
      font-size: 28px;
      letter-spacing: -1px;
      color: #9aa3ae;
      font-weight: 600;
      text-align: center; }
    .Home section.wable-system .cards {
      margin-top: 40px; }
      .Home section.wable-system .cards .box {
        width: 100%;
        padding: 36px 24px;
        border-radius: 5px;
        border: solid 1px #d7e3ee;
        background-color: #ffffff;
        margin-top: 12px;
        text-align: center; }
        .Home section.wable-system .cards .box .image-wrap {
          margin: 24px 0 16px 0;
          height: 152px;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          -webkit-align-items: center;
                  align-items: center; }
          .Home section.wable-system .cards .box .image-wrap > div {
            width: 230px;
            height: 152px; }
            .Home section.wable-system .cards .box .image-wrap > div img {
              max-width: 100%;
              max-height: 100%; }
        .Home section.wable-system .cards .box button {
          margin-top: 8px; }
        .Home section.wable-system .cards .box .card-title {
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          color: #414a53; }
        .Home section.wable-system .cards .box .card-desc {
          line-height: 1.75;
          letter-spacing: -1px;
          color: #6d747e; }
  .Home section.reviews .numbers-wrap {
    display: -webkit-flex;
    display: flex;
    margin: 0 -20px; }
    .Home section.reviews .numbers-wrap .numbers {
      -webkit-flex: 1 1;
              flex: 1 1;
      text-align: center; }
      .Home section.reviews .numbers-wrap .numbers .title {
        font-weight: 600;
        letter-spacing: -1px;
        color: #9aa3ae; }
      .Home section.reviews .numbers-wrap .numbers .number {
        display: inline-block;
        margin-top: 20px;
        width: 108px;
        height: 41px;
        border-radius: 41px;
        background-color: #d9eaff;
        font-size: 24px;
        font-weight: 600;
        color: #0069ff; }
  .Home section.reviews .review-swiper {
    overflow: hidden;
    -webkit-align-items: stretch;
            align-items: stretch;
    position: relative;
    margin: 0 -47px;
    padding: 0 36px; }
    .Home section.reviews .review-swiper .swiper-button-prev:after, .Home section.reviews .review-swiper .swiper-button-next:after {
      font-size: 22px; }
    .Home section.reviews .review-swiper .swiper-button-prev {
      left: 0; }
    .Home section.reviews .review-swiper .swiper-button-next {
      right: 0; }
  .Home section.reviews .review-wrap {
    margin-top: 56px; }
    .Home section.reviews .review-wrap .title {
      margin-bottom: 32px;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -1px;
      color: #22292f; }
      .Home section.reviews .review-wrap .title img {
        height: 1.5rem;
        position: relative;
        top: -4px;
        margin-right: 0.5rem; }
    .Home section.reviews .review-wrap .review {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #d7e3ee;
      line-height: 1.57;
      font-size: 14px;
      letter-spacing: -1px; }
      .Home section.reviews .review-wrap .review:last-child {
        border-bottom: none; }
      .Home section.reviews .review-wrap .review .content {
        color: #6d747e; }
      .Home section.reviews .review-wrap .review .writer {
        font-weight: 800;
        color: #22292f;
        font-size: 16px; }
      .Home section.reviews .review-wrap .review .service {
        margin: 1.4rem 0;
        font-weight: 600;
        color: #bbc8d3; }
  .Home section.wable-news .news-wrap {
    margin-top: 60px; }
    .Home section.wable-news .news-wrap:first-child {
      margin-top: 0; }
    .Home section.wable-news .news-wrap .title {
      font-weight: 600;
      letter-spacing: -1px;
      color: #414a53; }
    .Home section.wable-news .news-wrap .news-card-wrap {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .Home section.wable-news .news-wrap .news-card-wrap .news-card {
        margin-top: 24px;
        margin-left: 2%;
        margin-right: 2%;
        width: 46%; }
        .Home section.wable-news .news-wrap .news-card-wrap .news-card img {
          width: 100%;
          opacity: 0.8; }
          .Home section.wable-news .news-wrap .news-card-wrap .news-card img:hover {
            opacity: 0.9; }
        .Home section.wable-news .news-wrap .news-card-wrap .news-card .card-title {
          height: 32px;
          margin-top: 8px;
          font-size: 14px;
          line-height: 1.33;
          letter-spacing: -1px;
          color: #22292f;
          font-weight: 600; }
        .Home section.wable-news .news-wrap .news-card-wrap .news-card .card-desc {
          display: none; }
  .Home section.wable-expertise .section-title {
    font-size: 28px;
    letter-spacing: -1px;
    color: #9aa3ae;
    font-weight: 600;
    text-align: center; }
  .Home section.wable-expertise .expertise-wrap {
    margin-top: 60px; }
    .Home section.wable-expertise .expertise-wrap:first-child {
      margin-top: 0; }
    .Home section.wable-expertise .expertise-wrap .title {
      font-weight: 600;
      letter-spacing: -1px;
      color: #414a53; }
    .Home section.wable-expertise .expertise-wrap .expertise-card-wrap {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .Home section.wable-expertise .expertise-wrap .expertise-card-wrap .expertise-card {
        margin-top: 24px;
        margin-left: 2%;
        margin-right: 2%;
        width: 46%; }
        .Home section.wable-expertise .expertise-wrap .expertise-card-wrap .expertise-card img {
          width: 100%;
          opacity: 0.8; }
          .Home section.wable-expertise .expertise-wrap .expertise-card-wrap .expertise-card img:hover {
            opacity: 0.9; }
        .Home section.wable-expertise .expertise-wrap .expertise-card-wrap .expertise-card .card-title {
          height: 32px;
          margin-top: 8px;
          font-size: 14px;
          line-height: 1.33;
          letter-spacing: -1px;
          color: #22292f;
          font-weight: 600; }
        .Home section.wable-expertise .expertise-wrap .expertise-card-wrap .expertise-card .card-desc {
          display: none; }
  .Home section.one-last-thing {
    padding: 40px 0;
    text-align: center;
    background-image: linear-gradient(to top, #2138b2, #134ad4);
    margin-left: -16px;
    margin-right: -16px; }
    .Home section.one-last-thing .yanadoo {
      opacity: 0.7;
      font-size: 18px;
      line-height: 1.78;
      letter-spacing: -1px;
      color: #ffffff;
      margin-bottom: 24px; }
    .Home section.one-last-thing button {
      width: 195px;
      height: 54px;
      border-radius: 100px;
      background-color: #0069ff;
      border: none;
      color: #fff;
      -webkit-text-stroke: 0 #ffffff;
      font-weight: 600; }
  .Home .banners {
    position: fixed;
    top: 100px;
    margin: 0 auto;
    z-index: 10; }
    .Home .banners .banner {
      position: absolute;
      width: 90vw;
      background-color: #aaaaaa; }
      .Home .banners .banner .banner-img {
        width: 100%; }
      .Home .banners .banner .buttons {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 2px 10px; }
  @media (min-width: 1200px) {
    .Home section.hero {
      padding: 0;
      height: 663px; }
      .Home section.hero img.circle-small {
        top: 90px;
        left: 180px; }
      .Home section.hero img.circle-big {
        top: 130px;
        right: 130px; }
      .Home section.hero img.woman {
        display: inherit;
        width: 486px;
        height: 542px;
        bottom: -173px;
        right: 120px; }
      .Home section.hero .hero-inner .p-and-b {
        margin: 144px 0 0 244px;
        z-index: 2; }
      .Home section.hero .hero-inner .paragraph h1 {
        font-size: 40px;
        line-height: 1.4em; }
      .Home section.hero .primary-button {
        height: 55px;
        width: 260px; }
    .Home section.hero-b {
      height: 555px;
      padding: 100px 0 0 0;
      margin-left: -16px;
      margin-right: -16px;
      background: #1f7ced url("/images/home-hero-b-bg-pc.svg") no-repeat 70% 0;
      background-position: center; }
      .Home section.hero-b h1 {
        margin-left: 40px;
        font-size: 44px;
        line-height: 1.25;
        letter-spacing: -1px;
        color: #ffffff; }
      .Home section.hero-b .boxes-wrap {
        position: relative; }
        .Home section.hero-b .boxes-wrap .boxes {
          display: -webkit-flex;
          display: flex;
          position: absolute;
          margin-top: 88px; }
          .Home section.hero-b .boxes-wrap .boxes .box {
            width: 580px;
            padding: 48px 40px;
            margin-left: 40px; }
            .Home section.hero-b .boxes-wrap .boxes .box:first-child {
              margin-left: 0; }
            .Home section.hero-b .boxes-wrap .boxes .box.interpret {
              background-image: url("/images/home-wable-service-mic@2x.png");
              background-size: 200px 290px; }
            .Home section.hero-b .boxes-wrap .boxes .box.translate {
              background-image: url("/images/home-wable-service-paper@2x.png");
              background-size: 200px 300px; }
            .Home section.hero-b .boxes-wrap .boxes .box .title {
              line-height: 28px; }
              .Home section.hero-b .boxes-wrap .boxes .box .title h2 {
                font-size: 28px; }
              .Home section.hero-b .boxes-wrap .boxes .box .title h3 {
                font-size: 20px; }
              .Home section.hero-b .boxes-wrap .boxes .box .title span {
                font-size: 16px; }
    .Home section.services .title h2 {
      font-size: 24px;
      font-weight: 600;
      display: inline; }
    .Home section.services .title h3 {
      font-size: 20px;
      font-weight: 400;
      display: inline;
      color: #9b9b9b; }
    .Home section.services .title span {
      margin-left: 16px; }
    .Home section.services p {
      margin-top: 20px;
      font-size: 16px;
      line-height: 1.6; }
      .Home section.services p em {
        font-weight: 600; }
    .Home section.services .service-type {
      margin-top: 0;
      display: inline-block;
      width: 460px;
      vertical-align: top;
      margin-left: 100px; }
    .Home section.services .service-type:first-child {
      margin-top: 0; }
    .Home section.services .buttons {
      margin-top: 40px; }
      .Home section.services .buttons button {
        margin-right: 16px;
        padding: 12px 20px; }
    .Home section.wable-system.wable-system-c {
      padding-top: 160px;
      background-color: #ffffff; }
    .Home section.wable-system .section-title {
      font-size: 40px; }
    .Home section.wable-system .cards {
      margin-top: 48px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .Home section.wable-system .cards .box {
        width: 380px;
        padding: 48px 0; }
        .Home section.wable-system .cards .box .image-wrap {
          margin: 40px 0 32px 0;
          height: 180px; }
          .Home section.wable-system .cards .box .image-wrap > div {
            width: 270px;
            height: 180px; }
            .Home section.wable-system .cards .box .image-wrap > div img {
              max-width: 100%;
              max-height: 100%; }
        .Home section.wable-system .cards .box button {
          margin-top: 8px; }
        .Home section.wable-system .cards .box .card-title {
          font-size: 24px; }
    .Home section.reviews .numbers-wrap, .Home section.reviews .review-wrap {
      width: 800px;
      margin: 0 auto; }
    .Home section.reviews .review-swiper .swiper-slide {
      height: auto; }
    .Home section.reviews .review-swiper .swiper-button-prev:after, .Home section.reviews .review-swiper .swiper-button-next:after {
      font-size: 30px; }
    .Home section.reviews .numbers-wrap {
      display: -webkit-flex;
      display: flex;
      width: 800px;
      height: 64px;
      border-radius: 64px;
      background-color: #f1f8ff;
      -webkit-justify-content: center;
              justify-content: center; }
      .Home section.reviews .numbers-wrap .numbers {
        display: -webkit-flex;
        display: flex;
        height: 64px;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
        .Home section.reviews .numbers-wrap .numbers .title, .Home section.reviews .numbers-wrap .numbers .number {
          display: inline-block;
          height: 64px;
          line-height: 64px; }
        .Home section.reviews .numbers-wrap .numbers .title {
          font-size: 20px; }
        .Home section.reviews .numbers-wrap .numbers .number {
          background-color: inherit;
          font-size: 40px;
          margin-top: 0;
          margin-left: 16px;
          border-radius: 0;
          width: inherit; }
          .Home section.reviews .numbers-wrap .numbers .number .unit {
            font-size: 32px; }
    .Home section.reviews .review-wrap {
      margin-top: 56px; }
      .Home section.reviews .review-wrap .title {
        margin-bottom: 24px;
        margin-left: 16px; }
      .Home section.reviews .review-wrap .review {
        height: 100%;
        margin: 0 8px 8px;
        font-size: 14px;
        padding: 24px 36px;
        border-radius: 5px;
        border: 1px solid #d7e3ee; }
        .Home section.reviews .review-wrap .review:last-child {
          border: 1px solid #d7e3ee; }
    .Home section.wable-news .news-wrap {
      margin-top: 90px; }
      .Home section.wable-news .news-wrap:first-child {
        margin-top: 0; }
      .Home section.wable-news .news-wrap .title {
        font-size: 20px; }
      .Home section.wable-news .news-wrap .news-card-wrap {
        margin-top: 40px; }
        .Home section.wable-news .news-wrap .news-card-wrap .news-card {
          margin: 0;
          width: 280px; }
          .Home section.wable-news .news-wrap .news-card-wrap .news-card .card-title {
            height: auto;
            margin-top: 16px;
            font-size: 16px; }
          .Home section.wable-news .news-wrap .news-card-wrap .news-card .card-desc {
            display: block;
            color: #9aa3ae; }
    .Home section.wable-expertise .section-title {
      font-size: 40px; }
    .Home section.wable-expertise .expertise-wrap {
      margin-top: 90px; }
      .Home section.wable-expertise .expertise-wrap:first-child {
        margin-top: 0; }
      .Home section.wable-expertise .expertise-wrap .title {
        font-size: 20px; }
      .Home section.wable-expertise .expertise-wrap .expertise-card-wrap {
        margin-top: 40px; }
        .Home section.wable-expertise .expertise-wrap .expertise-card-wrap .expertise-card {
          margin: 0;
          width: 210px; }
          .Home section.wable-expertise .expertise-wrap .expertise-card-wrap .expertise-card .card-title {
            height: auto;
            margin-top: 16px;
            font-size: 16px; }
          .Home section.wable-expertise .expertise-wrap .expertise-card-wrap .expertise-card .card-desc {
            display: block;
            color: #9aa3ae; }
    .Home section.one-last-thing {
      padding: 52px 0;
      text-align: left; }
      .Home section.one-last-thing > div {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        padding: 0 52px; }
      .Home section.one-last-thing .yanadoo {
        font-size: 28px;
        -webkit-flex: 1 1;
                flex: 1 1;
        margin-bottom: 0; }
      .Home section.one-last-thing button {
        width: 195px;
        height: 54px;
        border-radius: 100px;
        background-color: #0069ff;
        border: none;
        color: #fff;
        -webkit-text-stroke: 0 #ffffff;
        font-weight: 600; }
    .Home .banners {
      top: 100px;
      left: 150px;
      margin: 0; }
      .Home .banners .banner {
        width: 400px; } }
  .Home .fade {
    transition: 2s opacity, 2s -webkit-transform;
    transition: 2s opacity, 2s transform;
    transition: 2s opacity, 2s transform, 2s -webkit-transform; }
  .Home .fade:not(.show) {
    opacity: 0;
    -webkit-transform: translateY(70px);
            transform: translateY(70px); }
  .Home .show {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }

@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 70px, 0);
            transform: translate3d(0, 70px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 70px, 0);
            transform: translate3d(0, 70px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both; }

.animatedFadeInUp {
  opacity: 0; }

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.Header {
  border-bottom: 1px solid #eeeeee;
  position: absolute;
  width: 100%;
  z-index: 10;
  background: #ffffff;
  font-size: 14px; }
  .Header.fixed {
    position: fixed;
    top: 0;
    left: 0; }
  .Header .bi {
    font-size: 28px;
    line-height: 28px;
    color: #0069ff;
    font-weight: 600;
    position: relative;
    top: -3px; }
  .Header a {
    color: #22292F; }
  .Header .navbar-toggler {
    margin-right: -15px; }

.blinder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); }

.CircleBorderButton {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  color: #6d747e;
  font-weight: 600;
  border: none;
  line-height: 1em; }
  .CircleBorderButton.active {
    background-color: #e3efff;
    color: #0069ff;
    border-radius: 100px; }

.TertiaryButton {
  padding: 12px 40px;
  color: #0069ff;
  font-weight: 600;
  border: 1px solid #0069ff;
  line-height: 1em;
  background-color: #ffffff;
  border-radius: 2px;
  margin-right: 30px; }
  .TertiaryButton.active {
    background-color: #e3efff;
    color: #0069ff;
    border-radius: 100px; }
  .TertiaryButton.small {
    font-size: 14px;
    padding: 12px 20px; }

.admin-root {
  display: -webkit-flex;
  display: flex; }

.AdminHeader {
  width: 300px;
  padding: 30px 20px;
  border-right: 1px solid #eaeaea; }

.blinder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); }

.admin-contents {
  padding: 50px;
  width: 100%; }
  .admin-contents a {
    color: #007bff;
    text-decoration: underline; }
  .admin-contents a.disabled {
    color: #999;
    text-decoration: none;
    cursor: default; }

.Footer {
  border-top: 1px solid #eeeeee;
  line-height: 1.6em;
  padding: 16px 32px 64px 32px; }
  .Footer .company-info span {
    line-height: 20px; }
  .Footer .info {
    color: #b3b3b3;
    font-size: 12px;
    margin: 16px 0;
    clear: both; }
    .Footer .info a {
      margin-right: 16px; }
  .Footer .bi img {
    height: 18px; }
  @media (min-width: 992px) {
    .Footer .info ul li {
      float: left;
      margin-right: 16px; } }

.KakaoFloating {
  position: fixed;
  z-index: 110;
  right: 100px;
  bottom: 32px; }
  .KakaoFloating img {
    width: 120px; }
  @media (max-width: 1200px) {
    .KakaoFloating {
      right: 32px;
      bottom: 32px; } }
  @media (max-width: 768px) {
    .KakaoFloating {
      right: 16px;
      bottom: 16px; } }

@charset "UTF-8";
html {
  font-size: 16px;
  line-height: 1.6; }

body {
  color: #414A53;
  max-width: 100vw;
  overflow-x: hidden; }

mark {
  background-color: #fcf8e3; }

ul {
  list-style-type: none;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0; }

em {
  font-style: normal; }

::selection {
  color: inherit; }

.wable-button {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-color: #ffffff;
  font-weight: bold;
  line-height: 1; }
  .wable-button.primary {
    background-color: #0069ff;
    border-color: #0069ff;
    color: #ffffff;
    box-shadow: 0 4px 4px 0 rgba(211, 211, 211, 0.6); }
  .wable-button.secondary {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0069ff; }
  .wable-button.black {
    color: #ffffff !important;
    border-color: #22292f !important;
    background-color: #22292f;
    font-weight: normal !important; }
  .wable-button.line {
    border-color: #d7e3ee !important;
    color: #22292f;
    font-weight: normal !important; }
  @media (min-width: 992px) {
    .wable-button {
      max-width: 288px; } }

.only-pc {
  display: none; }

.only-mobile {
  display: block; }

@media (min-width: 992px) {
  .only-pc {
    display: block; }
  .only-mobile {
    display: none; } }

.contents-body {
  padding: 100px 16px 32px 16px;
  min-height: 800px; }
  @media (max-width: 480px) {
    .contents-body {
      padding: 0px 20px 32px 20px;
      overflow-x: hidden; } }

.contents-body-v2 {
  padding: 57px 16px 32px 16px; }
  @media (min-width: 1200px) {
    .contents-body-v2 {
      padding: 57px 0 0 0;
      min-height: 800px; }
      .contents-body-v2 .inner {
        width: 1200px;
        margin: 0 auto; } }

.gray-dark {
  color: #999999; }

.gray-dark2 {
  color: #777777; }

.tp-button.small {
  padding: 8px 10px; }
  .tp-button.small span {
    font-size: 12px !important; }

.inner {
  width: 100%;
  margin: 0; }
  @media (min-width: 992px) {
    .inner {
      width: 966px;
      margin: 0 auto; } }

.headerinner {
  width: 100%;
  margin: 100; }
  @media (min-width: 992px) {
    .headerinner {
      width: 100%;
      margin: 0 auto; } }

.color {
  color: #3D91CE;
  color: #B3DAF3;
  color: #CC2D2D;
  color: #1DA549;
  color: #E0EAF7; }

.blue-button {
  padding: 0.75em 2em;
  color: #3D91CE;
  border: 1px solid #3D91CE;
  font-weight: 600; }

.status.TEMP {
  color: #ff0000; }

.status.RECRUIT_WAITING {
  color: #3D91CE; }

.status.RECRUIT_INPROGRESS {
  color: green; }

.status.RECRUIT_CLOSED {
  color: #3D91CE; }

.status.PROJECT_INPROGRESS {
  color: green; }

.status.DONE {
  color: #08c115; }

.status.DELETED {
  color: gray; }

.status.CANCELED {
  color: gray; }

.badge {
  padding: 6px 10px !important;
  border-radius: 10px !important;
  font-weight: 600 !important; }

.badge-primary {
  background-color: #b3ddf5 !important;
  color: #333333 !important; }

.card {
  border-radius: 0 !important; }

.profile-image-wrap1 {
  width: 100%;
  height: 240px;
  background: no-repeat 50%;
  background-size: cover; }
  .profile-image-wrap1.sm {
    width: 120px;
    height: 150px; }
  @media (max-width: 934px) {
    .profile-image-wrap1 {
      height: 93px; } }

.profile-image-wrap2 {
  width: 100px;
  height: 100px;
  background: no-repeat 50%;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .profile-image-wrap2.sm {
    width: 120px;
    height: 150px; }
  @media (max-width: 934px) {
    .profile-image-wrap2 {
      width: 60px;
      height: 60px; } }

.pointer {
  cursor: pointer; }

.small {
  font-size: 0.9em !important; }

.warning_visible {
  color: #868686;
  font-size: 14px; }

.warning {
  color: #dd2c00;
  font-size: 14px; }

.warning_url {
  visibility: hidden;
  color: #dd2c00;
  font-size: 14px; }

.underline {
  text-decoration: underline; }

.color-primary {
  color: #0069ff; }

select::-ms-expand {
  display: none; }

input[readonly] {
  cursor: default; }

.Input_inputStylesUiStateReadonly__25u3u {
  border-color: #fff; }

.BlockList_list__24dsI {
  background: #ffffff; }

.BlockList_list__24dsI.horizontal {
  display: -webkit-flex;
  display: flex; }

.ccc {
  color: #cccccc; }

.bbb {
  color: #bbbbbb; }

ul {
  list-style-type: none; }

.custom-file-input2 {
  color: #ffffff;
  width: 90px; }
  .custom-file-input2 input[type=file]::-webkit-file-upload-button {
    visibility: hidden; }
  .custom-file-input2 input[type=file]::before {
    content: '\C5C5\B85C\B4DC';
    display: inline-block;
    border-radius: 3px;
    padding: 0 22px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: #1f7ced;
    background-color: #ffffff;
    border: 2px solid #d3d4d5;
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
    min-height: 36px; }
  .custom-file-input2 input[type=file]:hover::before {
    border-color: #1f7ced; }
  .custom-file-input2 input[type=file]:active::before {
    background: #ffffff; }

.tp-select-readonly {
  border: 1px solid #ffffff !important;
  background-image: none !important;
  color: #333333 !important;
  background-color: #ffffff !important;
  opacity: 1 !important; }

button.primary {
  width: 100%;
  height: 50px;
  border-radius: 2px;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #0069ff;
  border: none;
  color: #fff;
  font-weight: bold;
  padding: 0 2.5em; }
  @media (min-width: 992px) {
    button.primary {
      width: inherit; } }

body {
  font-family: "Noto Sans KR", "\B9D1\C740   \ACE0\B515", "malgun gothic", "apple sd gothic neo", applegothic, sans-serif;
  font-weight: 400;
  font-size: 16px; }

/*
This document has been created with Marked.app <http://marked2app.com>, Copyright 2013 Brett Terpstra
Content is property of the document author
Please leave this notice in place, along with any additional credits below.
---------------------------------------------------------------
Title: GitHub
Author: Brett Terpstra
Description: Github README style. Includes theme for Pygmentized code blocks.
*/
#wrapper {
  font: 15px helvetica,arial,freesans,clean,sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.7;
  padding: 3px;
  background: #fff;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px; }
  #wrapper p {
    margin: 1em 0; }
  #wrapper a {
    color: #4183c4;
    text-decoration: none; }
  #wrapper > *:first-child {
    margin-top: 0 !important; }
  #wrapper > *:last-child {
    margin-bottom: 0 !important; }
  #wrapper h1, #wrapper h2, #wrapper h3, #wrapper h4, #wrapper h5, #wrapper h6 {
    font-weight: 600;
    line-height: 1.7;
    cursor: text;
    position: relative;
    margin: 1em 0 15px;
    padding: 0; }
  #wrapper h1 {
    font-size: 2.5em;
    border-bottom: 1px solid #ddd; }
  #wrapper h2 {
    font-size: 2em;
    border-bottom: 1px solid #eee; }
  #wrapper h3 {
    font-size: 1.5em; }
  #wrapper h4 {
    font-size: 1.2em; }
  #wrapper h5 {
    font-size: 1em; }
  #wrapper h6 {
    color: #777;
    font-size: 1em; }
  #wrapper p, #wrapper blockquote, #wrapper table, #wrapper pre {
    margin: 15px 0; }
  #wrapper ul {
    padding-left: 30px; }
  #wrapper ol {
    padding-left: 30px; }
  #wrapper ol li ul:first-of-type {
    margin-top: 0px; }
  #wrapper hr {
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAECAYAAACtBE5DAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OENDRjNBN0E2NTZBMTFFMEI3QjRBODM4NzJDMjlGNDgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OENDRjNBN0I2NTZBMTFFMEI3QjRBODM4NzJDMjlGNDgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4Q0NGM0E3ODY1NkExMUUwQjdCNEE4Mzg3MkMyOUY0OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4Q0NGM0E3OTY1NkExMUUwQjdCNEE4Mzg3MkMyOUY0OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqqezsUAAAAfSURBVHjaYmRABcYwBiM2QSA4y4hNEKYDQxAEAAIMAHNGAzhkPOlYAAAAAElFTkSuQmCC) repeat-x 0 0;
    border: 0 none;
    color: #ccc;
    height: 4px;
    margin: 15px 0;
    padding: 0; }
  #wrapper em {
    text-decoration: underline;
    font-style: normal; }
  #wrapper > h2:first-child {
    margin-top: 0;
    padding-top: 0; }
  #wrapper > h1:first-child {
    margin-top: 0;
    padding-top: 0; }
  #wrapper > h1:first-child + h2 {
    margin-top: 0;
    padding-top: 0; }
  #wrapper > h3:first-child, #wrapper > h4:first-child, #wrapper > h5:first-child, #wrapper > h6:first-child {
    margin-top: 0;
    padding-top: 0; }
  #wrapper a:first-child h1, #wrapper a:first-child h2, #wrapper a:first-child h3, #wrapper a:first-child h4, #wrapper a:first-child h5, #wrapper a:first-child h6 {
    margin-top: 0;
    padding-top: 0; }
  #wrapper h1 + p, #wrapper h2 + p, #wrapper h3 + p, #wrapper h4 + p, #wrapper h5 + p, #wrapper h6 + p, #wrapper ul li > :first-child, #wrapper ol li > :first-child {
    margin-top: 0; }
  #wrapper dl {
    padding: 0; }
  #wrapper dl dt {
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    padding: 0;
    margin: 15px 0 5px; }
  #wrapper dl dt:first-child {
    padding: 0; }
  #wrapper dl dt > :first-child {
    margin-top: 0; }
  #wrapper dl dt > :last-child {
    margin-bottom: 0; }
  #wrapper dl dd {
    margin: 0 0 15px;
    padding: 0 15px; }
  #wrapper dl dd > :first-child {
    margin-top: 0; }
  #wrapper dl dd > :last-child {
    margin-bottom: 0; }
  #wrapper blockquote {
    border-left: 4px solid #DDD;
    padding: 0 15px;
    color: #777; }
  #wrapper blockquote > :first-child {
    margin-top: 0; }
  #wrapper blockquote > :last-child {
    margin-bottom: 0; }
  #wrapper table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 100%;
    font: inherit; }
  #wrapper table th {
    font-weight: 600;
    border: 1px solid #ccc;
    padding: 6px 13px; }
  #wrapper table td {
    border: 1px solid #ccc;
    padding: 6px 13px; }
  #wrapper table tr {
    border-top: 1px solid #ccc;
    background-color: #fff; }
  #wrapper table tr:nth-child(2n) {
    background-color: #f8f8f8; }
  #wrapper img {
    max-width: 100%; }
  #wrapper code, #wrapper tt {
    margin: 0 2px;
    padding: 0 5px;
    white-space: nowrap;
    border: 1px solid #eaeaea;
    background-color: #f8f8f8;
    border-radius: 3px;
    font-family: Consolas, 'Liberation Mono', Courier, monospace;
    font-size: 12px;
    color: #333333; }
  #wrapper pre > code {
    margin: 0;
    padding: 0;
    white-space: pre;
    border: none;
    background: transparent; }
  #wrapper .highlight pre {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    font-size: 13px;
    line-height: 19px;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 3px; }
  #wrapper pre {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    font-size: 14px;
    line-height: 19px;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 3px;
    margin: 26px 0; }
  #wrapper pre code, #wrapper pre tt {
    background-color: transparent;
    border: none; }
  #wrapper .poetry pre {
    font-family: Georgia, Garamond, serif !important;
    font-style: italic;
    font-size: 110% !important;
    line-height: 1.6em;
    display: block;
    margin-left: 1em; }
  #wrapper .poetry pre code {
    font-family: Georgia, Garamond, serif !important;
    word-break: break-all;
    word-break: break-word;
    /* Non standard for webkit */
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
        hyphens: auto;
    white-space: pre-wrap; }
  #wrapper sup, #wrapper sub, #wrapper a.footnote {
    font-size: 1.4ex;
    height: 0;
    line-height: 1;
    vertical-align: super;
    position: relative; }
  #wrapper sub {
    vertical-align: sub;
    top: -1px; }
  #wrapper ul {
    list-style-type: square;
    line-height: 1.7em;
    padding-left: 1em; }
  @media (min-width: 992px) {
    #wrapper {
      padding: 30px; } }

.css2 {
  word-break: keep-all; }
  .css2 h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3em;
    letter-spacing: -1px; }
    .css2 h1 em {
      font-weight: 600; }
  .css2 em {
    color: #0b79ff;
    font-style: normal; }
  .css2 h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5em; }
  .css2 p {
    font-size: 16px;
    line-height: 1.43em;
    margin-top: 1em;
    word-break: keep-all; }
  .css2 .inner {
    width: 100%;
    max-width: 100%; }
  .css2 .primary-button, .css2 .text-button, .css2 .primary-line-button, .css2 .secondary-line-button {
    line-height: 1.44;
    font-size: 14px; }
  .css2 .primary-button {
    padding: 12px 60px;
    margin: 2px;
    border-radius: 2px;
    border: none;
    box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #0069ff;
    color: #fff;
    font-weight: 600; }
  .css2 .text-button, .css2 .primary-line-button, .css2 .secondary-line-button {
    padding: 8px 16px;
    background-color: transparent;
    font-size: 14px;
    border-radius: 2px;
    font-weight: 600; }
  .css2 .text-button {
    color: #0069ff;
    border: none; }
  .css2 .primary-line-button {
    color: #0069ff;
    border: 1px solid #0069ff; }
  .css2 .secondary-line-button {
    color: #6d747e;
    border: 1px solid #D7E3EE; }
  @media (min-width: 1200px) {
    .css2 .inner {
      width: 1200px; } }

a {
  color: inherit;
  text-decoration: none; }

a:hover {
  color: inherit;
  text-decoration: none !important; }

p {
  margin-top: 0;
  margin-bottom: 0; }

.Select_root__3aWbF {
  display: inherit; }

.Select_select__1FpUM {
  padding-right: 34px; }

.navbar {
  padding: 1rem 0 1rem 1rem; }

.navbar-toggler {
  border: none;
  padding: 0; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 7%, 0);
    transform: translate3d(0, 7%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 7%, 0);
    transform: translate3d(0, 7%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  transition-timing-function: step-start; }

input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block; }

.kontents {
  padding-top: 72px;
  min-height: 800px; }
  @media (max-width: 768px) {
    .kontents {
      padding-top: 72px; } }

.BusinessEnglish {
  margin-bottom: 64px;
  padding: 57px 0 0 0; }
  .BusinessEnglish .header {
    padding: 64px 32px;
    width: 100%;
    background-image: url("/images/pricing-header-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #0069ff;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    margin-bottom: 64px; }
    .BusinessEnglish .header .texts {
      width: 100%; }
      .BusinessEnglish .header .texts .catchphrase {
        font-size: 28px;
        line-height: 40px;
        font-weight: 600; }
      .BusinessEnglish .header .texts .subtext {
        margin: 40px 0;
        opacity: 0.7;
        font-size: 16px;
        line-height: 1.44; }
        .BusinessEnglish .header .texts .subtext > div {
          margin-top: 1rem; }
    .BusinessEnglish .header button {
      width: 326px;
      max-width: 100%;
      height: 54px;
      border-radius: 2px;
      box-shadow: 0 4px 4px 0 rgba(21, 77, 240, 0.6);
      border: solid 1px #ffffff;
      background-color: transparent;
      color: #ffffff;
      font-weight: 600; }
    .BusinessEnglish .header button:hover {
      background-color: #ffffff;
      color: #0b79ff; }
  .BusinessEnglish .tab-content {
    padding: 0 32px; }
  .BusinessEnglish .etc-item {
    border-bottom: 1px solid #dbe2eb;
    padding: 16px 0; }
    .BusinessEnglish .etc-item:last-child {
      border-bottom: none; }
    .BusinessEnglish .etc-item .title {
      color: #a4aebc;
      font-weight: 600; }
    .BusinessEnglish .etc-item .text {
      color: #5d6775;
      margin-top: 16px; }

.yellow-button {
  margin: 40px 32px 100px 32px;
  height: 64px;
  line-height: 64px;
  color: #ffffff;
  background-color: #22292f;
  border: none;
  text-align: center; }

@media (min-width: 768px) {
  .BusinessEnglish .header {
    padding: 80px;
    margin-bottom: 80px; }
    .BusinessEnglish .header .texts {
      margin: 0 auto;
      width: 720px; }
      .BusinessEnglish .header .texts .subtext > div {
        margin-top: 0; }
  .BusinessEnglish .tab-content {
    padding: 0;
    margin: 0 auto;
    width: 720px; }
    .BusinessEnglish .tab-content .etc-item .text {
      margin-top: 0; } }

.Modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: none;
  z-index: 9999;
  position: relative;
  width: 596px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  opacity: 1;
  outline: 0;
  overflow: visible;
  overflow-x: hidden; }
  @media (max-width: 934px) {
    .Modal {
      width: 90vw;
      max-height: 90vh;
      padding: 20px 20px 20px 20px; } }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9000; }

.ProjectView {
  padding: 70px 0;
  width: 680px; }
  .ProjectView .poster {
    max-width: 100%;
    width: 100%; }
  .ProjectView .detail-row {
    margin-bottom: 10px; }
    .ProjectView .detail-row .label {
      font-weight: 600; }
  .ProjectView .introduce {
    padding: 1em;
    background: #eeeeee;
    border-radius: 1em; }
  @media (max-width: 934px) {
    .ProjectView {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px; } }

.projectCloseModal table {
  width: 100%;
  margin: 20px 0; }
  .projectCloseModal table th, .projectCloseModal table td {
    padding: 7px 15px; }
  .projectCloseModal table th {
    background: #9fcdff; }

.projectCloseModal .warning {
  color: #dd2c00;
  margin: 20px 0; }

.projectViewContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.ProjectApplyModal .input-row {
  display: -webkit-flex;
  display: flex;
  line-height: 30px; }
  .ProjectApplyModal .input-row .label {
    width: 90px;
    margin-right: 20px; }
  .ProjectApplyModal .input-row .input-wrap {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .ProjectApplyModal .input-row .won {
    margin-left: 6px; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.Modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: none;
  z-index: 9999;
  position: relative;
  width: 466px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  opacity: 1;
  outline: 0;
  overflow: visible;
  overflow-x: hidden; }
  @media (max-width: 934px) {
    .Modal {
      width: 90vw;
      max-height: 90vh;
      padding: 20px 20px 20px 20px; } }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9000; }

.Modal2 {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: none;
  z-index: 9999;
  position: relative;
  width: 452px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  opacity: 1;
  outline: 0;
  overflow: visible;
  overflow-x: hidden; }
  @media (max-width: 934px) {
    .Modal2 {
      width: 90vw;
      max-height: 90vh;
      padding: 20px 20px 20px 20px; } }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9000; }

