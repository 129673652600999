
.TertiaryButton {
  padding: 12px 40px;
  color: #0069ff;
  font-weight: 600;
  border: 1px solid #0069ff;
  line-height: 1em;
  background-color: #ffffff;
  border-radius: 2px;
  margin-right: 30px;
  &.active {
    background-color: #e3efff;
    color: #0069ff;    
    border-radius: 100px;
  }
  &.small {
    font-size: 14px;    
    padding: 12px 20px;
  }
}