@import "../../../styles/color";

.Home {
  color: $grey100;
  padding: 57px 16px 0 16px !important;

  section {
    padding: 64px 24px;
  }

  section.services, section.reviews {
    padding: 64px 32px;
  }

  section.wable-news {
    padding: 32px 0 64px 0;    
  }

  section.wable-expertise {
    padding: 32px 0 64px 0;    
  }

  section.hero {
    background-color: #f1f6fd;
    padding: 80px 40px;
    margin-left: -16px;
    margin-right: -16px;

    img {
      position: absolute;
      z-index: 1;
    }

    img.circle-small {
      height: 110px;
      top: -50px;
      left: -24px;
    }
    img.circle-big {
      height: 183px;
      top: 75px;
      right: -120px;
    }
    img.woman {
      display: none;      
    }
    img.womannotlogin {
      transform: translateY(56px);
    }

    .hero-inner {
      display: flex;
      align-items: center;
      position: relative;

      .p-and-b {
        z-index: 2;
      }

      .paragraph {
        z-index: 2;
        h1 {
          font-size: 28px;
          line-height: 40px;
          letter-spacing: -1px;
          font-weight: 600;
          em {
            color: #0069ff;
          }
        }
      }
    }

    .button-wrap {
      margin-top: 60px;
      z-index: 2;
    }
  }

  section.hero-b {
    padding: 56px 12px;
    margin-left: -16px;
    margin-right: -16px;
    background-size: 276px 493px;
    background: #1f7ced url("/images/home-hero-b-bg.svg") no-repeat;


    h1 {
      font-size: 28px;
      font-weight: 600;
      line-height: 1.43;
      letter-spacing: -1px;
      color: #ffffff;
      margin-left: 12px;

      em {
        color: #ffd703
      }
    }

    .boxes {
      margin-top: 56px;

      .box {
        border-radius: 5px;
        margin-top: 16px;
        padding: 40px 30px;
        border: solid 1px $grey40;
        background: #ffffff no-repeat right;

        .title {
          color: #414a53;
          h2 {
            display: inline;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -1px;
          }
          span {
            margin-left: 8px;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: -1px;
            color: #414a53;
          }
        }
        p {
          margin-top: 16px;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -1px;
          color: $grey70;
          em {
            font-weight: 600;
          }
        }

        .buttons {
          margin-top: 48px;
          font-size: 14px;

          .primary-button {
            border-radius: 2px;
            background: #22292f;
            padding: 12px 32px;
            box-shadow: none;
          }

          .secondary-line-button {
            padding: 12px 16px;
            color: $grey100;
            margin-left: 8px;
          }
        }
      }
    }
  }

  section.services {
    h2 {
      font-size: 20px;
      font-weight: 600;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      color : #9b9b9b;
    }
    p {
      margin-top: 8px;
      color: #6d747e;
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: -1px;
    }

    .service-type {
      margin-top: 64px;
    }
    .service-type:first-child {
      margin-top: 0;
    }
    .buttons {
      margin-top: 24px;

      button {
        margin-right: 8px;
      }
    }
  }

  section.partners {
    background-color: #ffffff;
    margin-left: -16px;
    margin-right: -16px;

    .section-title {
      font-size: 28px;
      letter-spacing: -1px;
      color: $grey60;
      font-weight: 600;
      text-align: center;
    }

    .section-title-black {
      font-size: 24px;
      letter-spacing: -1px;
      color: #615f5f;
      font-weight: 500;
      text-align: center;
    }

    .cards {
      margin-top: 40px;

      .box {
        width: 100%;
        padding: 36px 24px;
        border-radius: 5px;
        border: solid 1px $grey40;
        background-color: #ffffff;
        margin-top: 12px;
        text-align: center;

        .image-wrap {
          margin: 24px 0 16px 0;
          height: 152px;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            width: 230px;
            height: 152px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }

        button {
          margin-top: 8px;
        }

        .card-title {
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          color: #414a53;
        }

        .card-desc {
          line-height: 1.75;
          letter-spacing: -1px;
          color: $grey70;
        }
      }
    }
  }
  

  section.wable-system {
    background-color: #f9fcff;
    margin-left: -16px;
    margin-right: -16px;

    .section-title {
      font-size: 28px;
      letter-spacing: -1px;
      color: $grey60;
      font-weight: 600;
      text-align: center;
    }

    .cards {
      margin-top: 40px;

      .box {
        width: 100%;
        padding: 36px 24px;
        border-radius: 5px;
        border: solid 1px $grey40;
        background-color: #ffffff;
        margin-top: 12px;
        text-align: center;

        .image-wrap {
          margin: 24px 0 16px 0;
          height: 152px;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            width: 230px;
            height: 152px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }

        button {
          margin-top: 8px;
        }

        .card-title {
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          color: #414a53;
        }

        .card-desc {
          line-height: 1.75;
          letter-spacing: -1px;
          color: $grey70;
        }
      }
    }
  }

  section.reviews {
    .numbers-wrap {
      display: flex;
      margin: 0 -20px;

      .numbers {
        flex: 1;
        text-align: center;

        .title {
          font-weight: 600;
          letter-spacing: -1px;
          color: $grey60;
        }
        .number {
          display: inline-block;
          margin-top: 20px;
          width: 108px;
          height: 41px;
          border-radius: 41px;
          background-color: #d9eaff;
          font-size: 24px;
          font-weight: 600;
          color: #0069ff;
        }
      }
    }



    .review-swiper {
      overflow: hidden;
      align-items: stretch;
      position: relative;
      margin: 0 -47px;
      padding: 0 36px;

      .swiper-button-prev:after, .swiper-button-next:after {
        font-size: 22px;
      }

      .swiper-button-prev {
        left: 0;
      }
      .swiper-button-next {
        right: 0;
      }
    }

    .review-wrap {
      margin-top: 56px;

      .title {
        margin-bottom: 32px;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -1px;
        color: $grey100;
        img {
          height: 1.5rem;
          position: relative;
          top: -4px;
          margin-right: 0.5rem;
        }
      }

      .review {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $grey40;
        line-height: 1.57;
        font-size: 14px;
        letter-spacing: -1px;
        &:last-child {
          border-bottom: none;
        }

        .content {
          color: $grey70;
        }
        .writer {
          font-weight: 800;
          color: $grey100;
          font-size: 16px;
        }
        .service {
          margin: 1.4rem 0;
          font-weight: 600;
          color: $grey50;
        }
      }
    }
  }

  section.wable-news {

    
    .news-wrap {
      margin-top: 60px;
      

      &:first-child {
        margin-top: 0;
      }

      .title {
        font-weight: 600;
        letter-spacing: -1px;
        color: #414a53;
      }

      .news-card-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .news-card {
          margin-top: 24px;
          margin-left: 2%;
          margin-right: 2%;
          width: 46%;

          img {
            width: 100%;
            opacity: 0.8;

            &:hover {
              opacity: 0.9;
            }
          }
          .card-title {
            height: 32px;
            margin-top: 8px;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: -1px;
            color: $grey100;
            font-weight: 600;
          }
          .card-desc {
            display: none;
          }
        }
      }
    }
  }

  section.wable-expertise { 
    .section-title {
      font-size: 28px;
      letter-spacing: -1px;
      color: $grey60;
      font-weight: 600;
      text-align: center;
    }


    .expertise-wrap {
      margin-top: 60px;
      

      &:first-child {
        margin-top: 0;
      }

      .title {
        font-weight: 600;
        letter-spacing: -1px;
        color: #414a53;
      }

      .expertise-card-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .expertise-card {
          margin-top: 24px;
          margin-left: 2%;
          margin-right: 2%;
          width: 46%;

          img {
            width: 100%;
            opacity: 0.8;

            &:hover {
              opacity: 0.9;
            }
          }
          .card-title {
            height: 32px;
            margin-top: 8px;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: -1px;
            color: $grey100;
            font-weight: 600;
          }
          .card-desc {
            display: none;
          }
        }
      }
    }
  }

  section.one-last-thing {
    padding: 40px 0;
    text-align: center;
    background-image: linear-gradient(to top, #2138b2, #134ad4);
    margin-left: -16px;
    margin-right: -16px;

    .yanadoo {
      opacity: 0.7;
      font-size: 18px;
      line-height: 1.78;
      letter-spacing: -1px;
      color: #ffffff;
      margin-bottom: 24px;
    }

    button {
      width: 195px;
      height: 54px;
      border-radius: 100px;
      background-color: $blue1;
      border: none;
      color: #fff;
      -webkit-text-stroke: 0 #ffffff;
      font-weight: 600;
    }
  }


  .banners {
    position: fixed;
    top: 100px;
    margin: 0 auto;
    z-index: 10;

    .banner {
      position: absolute;
      width: 90vw;
      background-color: #aaaaaa;

      .banner-img {
        width: 100%;
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        padding: 2px 10px;
      }
    }
  }

  @media (min-width: 1200px) {
    section.hero {
      padding: 0;
      height: 663px;

      img.circle-small {
        top: 90px;
        left: 180px;
      }
      img.circle-big {
        top: 130px;
        right: 130px;
      }
      img.woman {
        display: inherit;
        width: 486px;
        height: 542px;
        bottom: -173px;
        right: 120px;
      }
      .hero-inner {

        .p-and-b {
          margin: 144px 0 0 244px;
          z-index: 2;
        }
        .paragraph {
          h1 {
            font-size: 40px;
            line-height: 1.4em;
          }
        }
      }

      .primary-button {
        height: 55px;
        width: 260px;
      }
    }

    section.hero-b {
      height: 555px;
      padding: 100px 0 0 0;
      margin-left: -16px;
      margin-right: -16px;
      background: #1f7ced url("/images/home-hero-b-bg-pc.svg") no-repeat 70% 0;
      background-position: center;


      h1 {
        margin-left: 40px;
        font-size: 44px;
        line-height: 1.25;
        letter-spacing: -1px;
        color: #ffffff;
      }

      .boxes-wrap {
        position: relative;
        .boxes {
          display: flex;
          position: absolute;
          margin-top: 88px;

          .box {
            width: 580px;
            padding: 48px 40px;
            margin-left: 40px;

            &:first-child {
              margin-left: 0;
            }

            &.interpret {
              background-image: url("/images/home-wable-service-mic@2x.png");
              background-size: 200px 290px;
            }
            &.translate {
              background-image: url("/images/home-wable-service-paper@2x.png");
              background-size: 200px 300px;
            }

            .title {
              line-height: 28px;

              h2 {
                font-size: 28px;
              }
              h3 {
                font-size: 20px;
              }
              span {
                font-size: 16px;
              }
            }
          }
        }

      }
    }

    section.services {
      .title {
        h2 {
          font-size: 24px;
          font-weight: 600;
          display: inline;
        }
        h3 {
          font-size: 20px;
          font-weight: 400;
          display: inline;
          color: #9b9b9b;
        }
        span {
          margin-left: 16px;
        }
      }

      p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 1.6;

        em {
          font-weight: 600;
        }
      }

      .service-type {
        margin-top: 0;
        display: inline-block;
        width: 460px;
        vertical-align: top;
        margin-left: 100px;
      }
      .service-type:first-child {
        margin-top: 0;
      }
      .buttons {
        margin-top: 40px;

        button {
          margin-right: 16px;
          padding: 12px 20px;
        }
      }
    }


    section.wable-system {
      &.wable-system-c {
        padding-top: 160px;
        background-color: #ffffff;
      }

      .section-title {
        font-size: 40px;
      }

      .cards {
        margin-top: 48px;
        display: flex;
        justify-content: space-between;

        .box {
          width: 380px;
          padding: 48px 0;

          .image-wrap {
            margin: 40px 0 32px 0;
            height: 180px;

            > div {
              width: 270px;
              height: 180px;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }

          button {
            margin-top: 8px;
          }

          .card-title {
            font-size: 24px;
          }
        }
      }
    }

    section.reviews {
      .numbers-wrap, .review-wrap {
        width: 800px;
        margin: 0 auto;
      }

      .review-swiper {
        .swiper-slide {
          height: auto;
        }

        .swiper-button-prev:after, .swiper-button-next:after {
          font-size: 30px;
        }
      }

      .numbers-wrap {
        display: flex;
        width: 800px;
        height: 64px;
        border-radius: 64px;
        background-color: #f1f8ff;
        justify-content: center;

        .numbers {
          display: flex;
          height: 64px;
          justify-content: center;
          align-items: center;

          .title, .number {
            display: inline-block;
            height: 64px;
            line-height: 64px;
          }

          .title {
            font-size: 20px;
          }
          .number {
            background-color: inherit;
            font-size: 40px;
            margin-top: 0;
            margin-left: 16px;
            border-radius: 0;
            width: inherit;

            .unit {
              font-size: 32px;
            }
          }
        }
      }

      .review-wrap {
        margin-top: 56px;

        .title {
          margin-bottom: 24px;
          margin-left: 16px;
        }

        .review {
          height: 100%;
          margin: 0 8px 8px;
          font-size: 14px;
          padding: 24px 36px;
          border-radius: 5px;
          border: 1px solid $grey40;

          &:last-child {
            border: 1px solid $grey40;
          }
        }
      }
    }

    section.wable-news {
      .news-wrap {
        margin-top: 90px;

        &:first-child {
          margin-top: 0;
        }

        .title {
          font-size: 20px;
        }

        .news-card-wrap {
          margin-top: 40px;

          .news-card {
            margin: 0;
            width: 280px;

            .card-title {
              height: auto;
              margin-top: 16px;
              font-size: 16px;
            }
            .card-desc {
              display: block;
              color: $grey60;
            }
          }
        }
      }
    }

    section.wable-expertise {
      .section-title {
        font-size: 40px;
      }


      .expertise-wrap {
        margin-top: 90px;

        &:first-child {
          margin-top: 0;
        }

        .title {
          font-size: 20px;
        }

        .expertise-card-wrap {
          margin-top: 40px;

          .expertise-card {
            margin: 0;
            width: 210px;

            .card-title {
              height: auto;
              margin-top: 16px;
              font-size: 16px;
            }
            .card-desc {
              display: block;
              color: $grey60;
            }
          }
        }
      }
    }

    section.one-last-thing {
      padding: 52px 0;
      text-align: left;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 52px;
      }
      .yanadoo {
        font-size: 28px;
        flex: 1;
        margin-bottom: 0;
      }

      button {
        width: 195px;
        height: 54px;
        border-radius: 100px;
        background-color: $blue1;
        border: none;
        color: #fff;
        -webkit-text-stroke: 0 #ffffff;
        font-weight: 600;
      }
    }


    .banners {
      top: 100px;
      left: 150px;
      margin: 0;

      .banner {
        width: 400px;
      }
    }
  }

  .fade {
    transition: 2s opacity, 2s transform;
  }

  .fade:not(.show) {
    opacity: 0;
    transform: translateY(70px);
  }
  .show {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
      transform: translate3d(0,70px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,70px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
