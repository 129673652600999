@import "./Custom";
@import "./font";
@import "./MarkdownGithub";
@import "./css2";

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.Select_root__3aWbF {
  display: inherit;
}

.Select_select__1FpUM {
  padding-right: 34px;
}

.navbar {
  padding: 1rem 0 1rem 1rem;
}

.navbar-toggler {
  border: none;
  padding: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 7%, 0);
    transform: translate3d(0, 7%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  transition-timing-function: step-start;
}

input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block;
}

.kontents {
  padding-top: 72px;
  min-height: 800px;

  @media (max-width: 768px) {
    padding-top: 72px;
  }
}
