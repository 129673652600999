@import "color";

html {
  font-size: 16px;
  line-height: 1.6;
}
body {
  color: #414A53;
  max-width: 100vw;
  overflow-x: hidden;
}

mark {
  background-color: #fcf8e3;
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

em {
  font-style: normal;
}

::selection {
  color: inherit;
}

input::placeholder {
}

.wable-button {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-color: #ffffff;
  font-weight: bold;
  line-height: 1;

  &.primary {
    background-color: #0069ff;
    border-color: #0069ff;
    color: #ffffff;
    box-shadow: 0 4px 4px 0 rgba(211, 211, 211, 0.6);
  }
  &.secondary {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0069ff;
  }

  &.black {
    color: #ffffff !important;
    border-color: $grey100 !important;
    background-color: $grey100;
    font-weight: normal !important;
  }

  &.line {
    border-color: $grey40 !important;
    color: $grey100;
    font-weight: normal !important;
  }


  @media (min-width: 992px) {
    max-width: 288px;
  }
}

.only-pc {
  display: none;
}
.only-mobile {
  display: block;
}

@media (min-width: 992px) {
  .only-pc {
    display: block;
  }
  .only-mobile {
    display: none;
  }
}

.contents-body {
  padding: 100px 16px 32px 16px;
  min-height: 800px;

  @media (max-width: 480px) {
    padding: 0px 20px 32px 20px;
    overflow-x: hidden;
  }
}

.contents-body-v2 {
  padding: 57px 16px 32px 16px;


  @media (min-width: 1200px) {
    padding: 57px 0 0 0;
    min-height: 800px;

    .inner {
      width: 1200px;
      margin: 0 auto;
    }
  }
}

.gray-dark {
  color: #999999;
}

.gray-dark2 {
  color: #777777;
}

.tp-button.small {
  padding: 8px 10px;

  span {
    font-size: 12px !important;
  }
}

.inner {
  width: 100%;
  margin: 0;
  @media (min-width: 992px) {
    width: 966px;
    margin: 0 auto;
  }
}

.headerinner {
  width: 100%;
  margin: 100;
  @media (min-width: 992px) {
    width: 100%;
    margin: 0 auto;
  }
}

.color {
  color: #3D91CE;
  color: #B3DAF3;
  color: #CC2D2D;
  color: #1DA549;
  color: #E0EAF7;
}

.blue-button {
  padding: 0.75em 2em;
  color: #3D91CE;
  border: 1px solid #3D91CE;
  font-weight: 600;
}


.status.TEMP { color: #ff0000 }
.status.RECRUIT_WAITING { color: #3D91CE }
.status.RECRUIT_INPROGRESS { color: green }
.status.RECRUIT_CLOSED { color: #3D91CE }
.status.PROJECT_INPROGRESS { color: green }
.status.DONE { color: #08c115
}
.status.DELETED { color: gray }
.status.CANCELED { color: gray }

.badge {
  padding: 6px 10px !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
}
.badge-primary {
  background-color: #b3ddf5 !important;
  color: #333333 !important;
}

.card {
  border-radius: 0 !important;
}


.profile-image-wrap1 {
  width: 100%;
  height: 240px;
  background: no-repeat 50%;
  background-size: cover;

  &.sm {
    width: 120px;
    height: 150px;
  }

  @media (max-width: 934px) {
    height: 93px;
  }
}

.profile-image-wrap2 {
  width: 100px;
  height: 100px;
  background: no-repeat 50%;
  background-size: cover;
  border-radius: 50%;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  &.sm {
    width: 120px;
    height: 150px;
  }

  @media (max-width: 934px) {
    width: 60px;
    height: 60px;
  }
}


.pointer {
  cursor: pointer;
}

.small {
  font-size: 0.9em !important;
}

.warning_visible {
  color: #868686;
  font-size: 14px;

  @media (max-width: 934px) {
  }
}

.warning {
  color: #dd2c00;
  font-size: 14px;

  @media (max-width: 934px) {
  }
}

.warning_url {
  visibility: hidden;
  color: #dd2c00;
  font-size: 14px;

  @media (max-width: 934px) {
  }
}

.underline {
  text-decoration: underline;
}

.color-primary {
  color: #0069ff;
}

select::-ms-expand {
  display: none;
}

input[readonly] {
  cursor: default;
}
.Input_inputStylesUiStateReadonly__25u3u {
  border-color: #fff;
}
.BlockList_list__24dsI {
  background: #ffffff;
}
.BlockList_list__24dsI.horizontal {
  display: flex;
}

.ccc {
  color: #cccccc;
}
.bbb {
  color: #bbbbbb;
}

ul {
  list-style-type: none;
}

.custom-file-input2 {
  color: #ffffff;
  width: 90px;

  input[type=file] {
    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    &::before {
      content: '업로드';
      display: inline-block;
      border-radius: 3px;
      padding: 0 22px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      color:  #1f7ced;
      background-color: #ffffff;
      border: 2px solid #d3d4d5;
      font-size: 14px;
      font-weight: 600;
      line-height: 36px;
      min-height: 36px;
    }
    &:hover::before {
      border-color: #1f7ced;
    }
    &:active::before {
      background: #ffffff;
    }
  }
}

.tp-select-readonly {
  border: 1px solid #ffffff !important;
  background-image: none !important;
  color: #333333 !important;
  background-color: #ffffff !important;
  opacity: 1 !important;
}

button.primary {
width: 100%;
height: 50px;
border-radius: 2px;
box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.2);
background-color: #0069ff;
border: none;
color: #fff;
font-weight: bold;
padding: 0 2.5em;

@media (min-width: 992px) {
width: inherit;
}
}
