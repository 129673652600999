
.ProjectApplyModal {
  .input-row {
    display: flex;
    line-height: 30px;

    .label {
      width: 90px;
      margin-right: 20px;
    }
    .input-wrap {
      flex: 1;
    }
    .won {
      margin-left: 6px;
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}