.css2 {
  word-break: keep-all;

  h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3em;
    letter-spacing: -1px;

    em {
      font-weight: 600;
    }
  }

  em {
    color: #0b79ff;
    font-style: normal;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5em;
  }
  p {
    font-size: 16px;
    line-height: 1.43em;
    margin-top: 1em;
    word-break: keep-all;
  }

  .inner {
    width: 100%;
    max-width: 100%;
  }

  .primary-button, .text-button, .primary-line-button, .secondary-line-button {
    line-height: 1.44;
    font-size: 14px;
  }

  .primary-button {
    padding: 12px 60px;
    margin: 2px;
    border-radius: 2px;
    border: none;
    box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #0069ff;
    color: #fff;
    font-weight: 600;
  }

  .text-button, .primary-line-button, .secondary-line-button {
    padding: 8px 16px;
    background-color: transparent;
    font-size: 14px;
    border-radius: 2px;
    font-weight: 600;
  }
  .primary-line-button {

  }
  .text-button {
    color: #0069ff;
    border: none;
  }
  .primary-line-button {
    color: #0069ff;
    border: 1px solid #0069ff;
  }
  .secondary-line-button {
    color: #6d747e;
    border: 1px solid #D7E3EE;
  }
  @media (min-width: 1200px) {
    .inner {
      width: 1200px;
    }

  }
}
