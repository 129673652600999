
.admin-root {
  display: flex;

}

.AdminHeader {
  width: 300px;
  padding: 30px 20px;
  border-right: 1px solid #eaeaea;
}

.blinder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

.admin-contents {
  padding: 50px;
  width: 100%;

  a {
    color: #007bff;
    text-decoration: underline;
  }
  a.disabled {
    color: #999;
    text-decoration: none;
    cursor: default;
  }
}