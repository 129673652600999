
.CircleBorderButton {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  color: #6d747e;
  font-weight: 600;
  border: none;
  line-height: 1em;

  &.active {
    background-color: #e3efff;
    color: #0069ff;
    border-radius: 100px;
  }
}