.ProjectView {
  padding: 70px 0;
  width: 680px;

  .poster {
    max-width: 100%;
    width: 100%;
  }

  .detail-row {
    margin-bottom: 10px;

    .label {
      font-weight: 600;
    }
    .value {

    }
  }

  .introduce {
    padding: 1em;
    background: #eeeeee;
    border-radius: 1em;
  }

  @media (max-width: 934px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}


.projectCloseModal {
  table {
    width: 100%;
    margin: 20px 0;
    th, td {
      padding: 7px 15px;

    }
    th {
      background: #9fcdff;
    }
  }

  .warning {
    color: #dd2c00;
    margin: 20px 0;
  }
}

.projectViewContainer {
  display: flex;
  justify-content: center;
}