.Modal2 {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: none;
  z-index: 9999;
  position: relative;
  width: 452px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 1;
  outline: 0;
  // overflow: auto;
  overflow: visible;
  overflow-x: hidden;

  @media (max-width: 934px) {
    width: 90vw;
    // max-height: 100vh;
    max-height: 90vh;
    padding: 20px 20px 20px 20px;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9000;
}
