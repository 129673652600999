.KakaoFloating {
  position: fixed;
  z-index: 110;
  right: 100px;
  bottom: 32px;
  img {
    width: 120px;
  }

  @media (max-width: 1200px) {
    right: 32px;
    bottom: 32px;
  }
  @media (max-width: 768px) {
    right: 16px;
    bottom: 16px;
  }
}

